<template>
  <div class="content">
    <div class="cell">
      <div class="header">
        <div>
          {{ title }}
          <span style="color: #4b7be5">({{ list.data.length }})</span>
        </div>
        <template v-if="list.examine_status">
          <div class="tips" v-if="list.examine_status == 3" style="color: red">
            驳回原因:{{ list.reject_reason }}
          </div>
          <div
            class="tips"
            v-else-if="list.examine_status == 2"
            style="color: #13ce66"
          >
            审核通过
          </div>
          <div class="tips" v-else style="color: #000">未审核</div>
        </template>
      </div>
      <div class="item">
        <template v-if="list.examine_status">
          <div class="tips" v-if="list.examine_status == 3" style="color: red">
            <span style="margin-right: 10px">
              上次审核:{{
                JSON.parse(list.last_examine_snap)
                  ? JSON.parse(list.last_examine_snap).examine_status == 2
                    ? "通过"
                    : JSON.parse(list.last_examine_snap).examine_status == 3
                    ? "驳回"
                    : "未审核"
                  : ""
              }}&nbsp;&nbsp;&nbsp;{{ list.examine_at }}
            </span>
            <!-- 驳回原因:{{ list.reject_reason }} -->
            <div
              style="margin-top: 5px"
              v-if="
                JSON.parse(list.last_examine_snap) &&
                JSON.parse(list.last_examine_snap).examine_status == 3
              "
            >
              驳回原因:{{ JSON.parse(list.last_examine_snap).reject_reason }}
            </div>
            <el-button
              class="filter-item"
              type="primary"
              @click="onekeyDowload(list.data)"
            >
              一键下载
            </el-button>
          </div>
          <div
            class="tips"
            v-else-if="list.examine_status == 2"
            style="color: #13ce66"
          >
            <span style="margin-right: 10px">
              上次审核:{{
                JSON.parse(list.last_examine_snap)
                  ? JSON.parse(list.last_examine_snap).examine_status == 2
                    ? "通过"
                    : JSON.parse(list.last_examine_snap).examine_status == 3
                    ? "驳回"
                    : "未审核"
                  : ""
              }}&nbsp;&nbsp;&nbsp;{{ list.examine_at }}
            </span>
            <!-- 审核通过 -->
            <div
              style="margin-top: 5px"
              v-if="
                JSON.parse(list.last_examine_snap) &&
                JSON.parse(list.last_examine_snap).examine_status == 3
              "
            >
              驳回原因:{{ JSON.parse(list.last_examine_snap).reject_reason }}
            </div>
            <el-button
              class="filter-item"
              type="primary"
              @click="onekeyDowload(list.data)"
            >
              一键下载
            </el-button>
          </div>
          <div class="tips" v-else style="color: #000">
            <span style="margin-right: 10px">
              上次审核:{{
                JSON.parse(list.last_examine_snap)
                  ? JSON.parse(list.last_examine_snap).examine_status == 2
                    ? "通过"
                    : JSON.parse(list.last_examine_snap).examine_status == 3
                    ? "驳回"
                    : "未审核"
                  : ""
              }}&nbsp;&nbsp;&nbsp;{{ list.examine_at }}
            </span>
            <!-- 未审核 -->
            <div
              style="margin-top: 5px"
              v-if="
                JSON.parse(list.last_examine_snap) &&
                JSON.parse(list.last_examine_snap).examine_status == 3
              "
            >
              驳回原因:{{ JSON.parse(list.last_examine_snap).reject_reason }}
            </div>
            <el-button
              class="filter-item"
              type="primary"
              @click="onekeyDowload(list.data)"
            >
              一键下载
            </el-button>
          </div>
        </template>
      </div>
      <div class="item" v-for="(item, key) in list.data" :key="key">
        <div class="name">
          {{ key + 1 }}.文件名: {{ item.name }}
          <div style="margin-top: 10px">
            <span v-if="item.upload_at"> 上传时间:{{ item.upload_at }} </span>
          </div>
        </div>
        <div class="item_right" style="min-width: 188px">
          <div class="name">
            <!-- <span style="margin-right: 10px" v-if="item.upload_at">
              上传时间:{{ item.upload_at }}
            </span> -->
            <el-button
              v-if="isAssetTypeAnImage(item.url)"
              size="mini"
              type="success"
              @click="preview(item)"
            >
              预览
            </el-button>
          </div>
          <div class="name">
            <el-button size="mini" type="primary" @click="download(item)">
              下载
              <!-- <a :href="item.url.replace(/http:/, 'https:')" target="_parent">
              </a> -->
            </el-button>
          </div>
        </div>
        <!-- <div class="name">
            <el-button size="mini" type="danger">驳回</el-button>
          </div> -->
      </div>
      <el-button size="mini" style="margin-top: 10px" v-if="isTixi">
        提醒提交
      </el-button>
      <!-- <el-divider v-if="list.examine_status != 2"></el-divider> -->
      <template v-if="isBtn">
        <div class="bottom_row" v-if="list.examine_status == 1">
          <el-button size="mini" type="danger" @click="reject(list.id)"
            >驳回</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="pass(list.id)"
            :loading="passLoading"
            >通过</el-button
          >
          <!-- <el-button size="mini" type="primary" @click="batchShow=true">批量审核</el-button> -->
        </div>
        <div
          class="bottom_row"
          style="justify-content: center"
          v-else-if="
            list.data.length > 0 &&
            (list.examine_status == 2 || list.examine_status == 3)
          "
        >
          <el-button
            size="mini"
            type="info"
            @click="cashier(list.id)"
            :loading="cashierLoading"
          >
            撤回审核
          </el-button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import JSZip from "jszip";
import axios from "axios";
import fileSaver from "file-saver";
const getFile = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "arraybuffer",
    })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
};
export default {
  name: "Batch",
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
      default: 1,
    },
    isTixi: {
      type: Boolean,
      default: false,
    },
    isBtn: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "授课计划",
    },
    list: {
      type: Object,
      default: {
        data: [],
      },
    },
  },
  data() {
    return {
      passLoading: false,
      classdata: null,
    };
  },
  created() {},
  mounted() {
    this.classdata = JSON.parse(localStorage.getItem("classdata"));
  },
  methods: {
    onekeyDowload(list) {
      const data = [];
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      list.forEach((item) => {
        console.log(item, "==============");
        if (item.url) {
          let routeUrl = item.url.replace(/http:/, "https:");
          data.push({ url: routeUrl, name: item.name });
        }
      });
      console.log(data, "=============");
      console.log(this.text,'============this.text');
      // return
      // 需要下载打包的路径, 可以是本地相对路径, 也可以是跨域的全路径
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      data.forEach((item) => {
        const promise = getFile(item.url).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象
          // const arr_name = item.split("/");
          const file_name = item.name; // 获取文件名
          zip.file(file_name, data, { binary: true }); // 逐个添加文件
          cache[file_name] = data;
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          loading.close();
          // 生成二进制流
          fileSaver.saveAs(
            content,
            `${this.classdata.college.school.name}${this.classdata.college.name}${this.classdata.school_year}-${
              this.classdata.school_year - 0 + 1
            }年第${this.classdata.semester == 1 ? "一" : "二"}学期${
              this.classdata.name
            }--${this.classdata.major.name} ${this.text}.zip`
          ); // 利用file-saver保存文件
        });
      });
    },
    cashier(id) {
      this.cashierLoading = true;
      request({
        url: "/api/schoolend/eduAdmin/dataRevokeExamine",
        method: "post",
        data: { id },
      }).then((response) => {
        this.cashierLoading = false;
        this.$message({
          type: "success",
          message: "撤回成功",
        });
        this.$emit("refresh");
      });
    },
    getUrlBase64(url) {
      return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let img = new Image();
        img.crossOrigin = "Anonymous"; //允许跨域
        img.src = url;
        img.onload = function () {
          canvas.height = 300;
          canvas.width = 300;
          ctx.drawImage(img, 0, 0, 300, 300);
          let dataURL = canvas.toDataURL("image/png");
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    //文件类型判断
    isAssetTypeAnImage(ext) {
      var index = ext.lastIndexOf(".");
      var ext = ext.substr(index + 1);
      return (
        ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"].indexOf(
          ext.toLowerCase()
        ) !== -1
      );
    },
    judgeType(name, type) {
      let result = "";
      let suffix = "";
      let imglist = [];
      try {
        var flieArr = name.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      if (type == 1) {
        imglist = ["png", "jpg", "jpeg", "bmp", "gif", "pdf"];
      } else {
        imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      }
      result = imglist.some(function (item) {
        return item == suffix;
      });
      return result;
    },
    preview(item) {
      let res = this.judgeType(item.url,1);
      if (res) {
        let routeUrl = item.url.replace(/http:/, "https:");
        window.open(routeUrl, "_blank");
      } else {
        let routeUrl = item.url;
        let officeUrl =
          "https://view.officeapps.live.com/op/view.aspx?src=" + routeUrl;
        window.open(officeUrl, "_blank");
      }
    },
    download(item) {
      console.log(item);
      let res = this.judgeType(item.url,2);
      if (res) {
        this.getUrlBase64(item.url).then((base64) => {
          let link = document.createElement("a");
          link.href = base64;
          link.download = `${this.classdata.college.name}${
            this.classdata.school_year
          }-${this.classdata.school_year - 0 + 1}年第${
            this.classdata.semester == 1 ? "一" : "二"
          }学期${this.classdata.name}——${item.name}`;
          link.click();
        });
      } else {
        let routeUrl = item.url.replace(/http:/, "https:");
        window.open(
          routeUrl +
            "?attname=" +
            `${this.classdata.college.name}${this.classdata.school_year}-${
              this.classdata.school_year - 0 + 1
            }年第${this.classdata.semester == 1 ? "一" : "二"}学期${
              this.classdata.name
            }——${item.name}`,
          "_parent"
        );
      }
    },
    reject(id) {
      this.$prompt("请输入驳回原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          request({
            url: "/api/schoolend/eduAdmin/dataReject",
            method: "post",
            data: { id, reject_reason: value },
          }).then((response) => {
            this.$message({
              type: "success",
              message: "驳回成功",
            });
            this.$emit("refresh");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    pass(id) {
      this.passLoading = true;
      request({
        url: "/api/schoolend/eduAdmin/dataPass",
        method: "post",
        data: { id },
      }).then((response) => {
        this.passLoading = false;
        this.$message({
          type: "success",
          message: "审核通过",
        });
        this.$emit("refresh");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 60%;
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    // border-bottom: 1px solid #000;
    // padding-bottom: 10px;
  }
  .cell {
    padding: 10px 20px;
    border: 1px solid #c4c4c4;
    overflow: auto;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #c4c4c4;
      flex: 1 1 auto;
      .name {
        margin-right: 15px;
      }
      .select {
        width: 20px;
        height: 20px;
      }
      .item_right {
        display: flex;
      }
    }
  }
  .bottom_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 100px;
    margin-top: 20px;
  }
  .bottom {
    display: flex;
  }
}
</style>