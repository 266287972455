<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="scss" >
// body .el-table th.gutter {
//   display: table-cell !important;
// }
// .el-table--border th.gutter:last-of-type {
//   display: block !important;
// }
// .el-table__body {
//   overflow: hidden !important;
// }
/deep/ .el-table__cell {
  width: 50px !important;
}
.el-table--border th.gutter:last-of-type {
  display: table-cell !important;
  width: 50px !important;
}
.el-calendar-day {
  height: 150px !important;
  // height: auto !important;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>

