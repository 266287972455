import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 180 * 1000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers["Cache-Control"] = "no-cache";
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    if (res.code !== 0) {
      if (res.code != -2) {
        Message({
          message: res.msg,
          type: "error",
          duration: 3 * 1000,
        });
      }

      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === 401) {
        setTimeout(() => {
          store.dispatch("FedLogOut").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        }, 1500);
      }
      return Promise.reject(res);
    } else {
      return response.data;
    }
  },
  (error) => {
    console.log(error); // for debug
    let msg = "网络出错了";
    if (error.response && error.response.data && error.response.data.msg) {
      msg = error.response.data.msg;
    }
    Message({
      message: msg,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
