var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"cell"},[_c('div',{staticClass:"header"},[_c('div',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('span',{staticStyle:{"color":"#4b7be5"}},[_vm._v("("+_vm._s(_vm.list.data.length)+")")])]),(_vm.list.examine_status)?[(_vm.list.examine_status == 3)?_c('div',{staticClass:"tips",staticStyle:{"color":"red"}},[_vm._v(" 驳回原因:"+_vm._s(_vm.list.reject_reason)+" ")]):(_vm.list.examine_status == 2)?_c('div',{staticClass:"tips",staticStyle:{"color":"#13ce66"}},[_vm._v(" 审核通过 ")]):_c('div',{staticClass:"tips",staticStyle:{"color":"#000"}},[_vm._v("未审核")])]:_vm._e()],2),_c('div',{staticClass:"item"},[(_vm.list.examine_status)?[(_vm.list.examine_status == 3)?_c('div',{staticClass:"tips",staticStyle:{"color":"red"}},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(" 上次审核:"+_vm._s(JSON.parse(_vm.list.last_examine_snap) ? JSON.parse(_vm.list.last_examine_snap).examine_status == 2 ? "通过" : JSON.parse(_vm.list.last_examine_snap).examine_status == 3 ? "驳回" : "未审核" : "")+" "+_vm._s(_vm.list.examine_at)+" ")]),(
              JSON.parse(_vm.list.last_examine_snap) &&
              JSON.parse(_vm.list.last_examine_snap).examine_status == 3
            )?_c('div',{staticStyle:{"margin-top":"5px"}},[_vm._v(" 驳回原因:"+_vm._s(JSON.parse(_vm.list.last_examine_snap).reject_reason)+" ")]):_vm._e(),_c('el-button',{staticClass:"filter-item",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onekeyDowload(_vm.list.data)}}},[_vm._v(" 一键下载 ")])],1):(_vm.list.examine_status == 2)?_c('div',{staticClass:"tips",staticStyle:{"color":"#13ce66"}},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(" 上次审核:"+_vm._s(JSON.parse(_vm.list.last_examine_snap) ? JSON.parse(_vm.list.last_examine_snap).examine_status == 2 ? "通过" : JSON.parse(_vm.list.last_examine_snap).examine_status == 3 ? "驳回" : "未审核" : "")+" "+_vm._s(_vm.list.examine_at)+" ")]),(
              JSON.parse(_vm.list.last_examine_snap) &&
              JSON.parse(_vm.list.last_examine_snap).examine_status == 3
            )?_c('div',{staticStyle:{"margin-top":"5px"}},[_vm._v(" 驳回原因:"+_vm._s(JSON.parse(_vm.list.last_examine_snap).reject_reason)+" ")]):_vm._e(),_c('el-button',{staticClass:"filter-item",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onekeyDowload(_vm.list.data)}}},[_vm._v(" 一键下载 ")])],1):_c('div',{staticClass:"tips",staticStyle:{"color":"#000"}},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(" 上次审核:"+_vm._s(JSON.parse(_vm.list.last_examine_snap) ? JSON.parse(_vm.list.last_examine_snap).examine_status == 2 ? "通过" : JSON.parse(_vm.list.last_examine_snap).examine_status == 3 ? "驳回" : "未审核" : "")+" "+_vm._s(_vm.list.examine_at)+" ")]),(
              JSON.parse(_vm.list.last_examine_snap) &&
              JSON.parse(_vm.list.last_examine_snap).examine_status == 3
            )?_c('div',{staticStyle:{"margin-top":"5px"}},[_vm._v(" 驳回原因:"+_vm._s(JSON.parse(_vm.list.last_examine_snap).reject_reason)+" ")]):_vm._e(),_c('el-button',{staticClass:"filter-item",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onekeyDowload(_vm.list.data)}}},[_vm._v(" 一键下载 ")])],1)]:_vm._e()],2),_vm._l((_vm.list.data),function(item,key){return _c('div',{key:key,staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(key + 1)+".文件名: "+_vm._s(item.name)+" "),_c('div',{staticStyle:{"margin-top":"10px"}},[(item.upload_at)?_c('span',[_vm._v(" 上传时间:"+_vm._s(item.upload_at)+" ")]):_vm._e()])]),_c('div',{staticClass:"item_right",staticStyle:{"min-width":"188px"}},[_c('div',{staticClass:"name"},[(_vm.isAssetTypeAnImage(item.url))?_c('el-button',{attrs:{"size":"mini","type":"success"},on:{"click":function($event){return _vm.preview(item)}}},[_vm._v(" 预览 ")]):_vm._e()],1),_c('div',{staticClass:"name"},[_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.download(item)}}},[_vm._v(" 下载 ")])],1)])])}),(_vm.isTixi)?_c('el-button',{staticStyle:{"margin-top":"10px"},attrs:{"size":"mini"}},[_vm._v(" 提醒提交 ")]):_vm._e(),(_vm.isBtn)?[(_vm.list.examine_status == 1)?_c('div',{staticClass:"bottom_row"},[_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.reject(_vm.list.id)}}},[_vm._v("驳回")]),_c('el-button',{attrs:{"size":"mini","type":"success","loading":_vm.passLoading},on:{"click":function($event){return _vm.pass(_vm.list.id)}}},[_vm._v("通过")])],1):(
          _vm.list.data.length > 0 &&
          (_vm.list.examine_status == 2 || _vm.list.examine_status == 3)
        )?_c('div',{staticClass:"bottom_row",staticStyle:{"justify-content":"center"}},[_c('el-button',{attrs:{"size":"mini","type":"info","loading":_vm.cashierLoading},on:{"click":function($event){return _vm.cashier(_vm.list.id)}}},[_vm._v(" 撤回审核 ")])],1):_vm._e()]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }