<template>
  <div>
    <div class="title">
      学校:{{ data.college.school.name }}&nbsp;&nbsp; 
      院系:{{data.college.name}}&nbsp;&nbsp; 
      专业:{{ data.major.name }}&nbsp;&nbsp; 
      学年:{{data.school_year}}-{{ data.school_year - 0 + 1 }}&nbsp;&nbsp; 
      <template v-if="isShow">
      学期:第{{data.semester == 1 ? "一" : "二"}}学期 &nbsp;&nbsp; 
      </template>
      年级:{{ data.grade.name }}&nbsp;&nbsp;
      班级:{{ data.name }} &nbsp;&nbsp; 
      <template v-if="isShow">
      课程:{{data.major ? data.major.name : ""}}&nbsp;&nbsp;
      教师:{{ data.teacher ? data.teacher : "" }}
      </template>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  name: "Title",
  props: {
    data: {
      type: Object,
      default: {},
    },
    isShow:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.title{
    // margin: 20px 0
    margin-bottom: 20px;
}
</style>