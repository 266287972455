import Vue from "vue";
import Router from "vue-router";
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/error/401",
    component: () => import("@/views/error/401"),
    hidden: true,
  },
  {
    path: "/error/404",
    component: () => import("@/views/error/404"),
    hidden: true,
  },
  {
    path: "",
    component: Layout,
    name: "Index",
    hidden: true,
    children: [
      // {
      //   path: "/",
      //   name: "Dashboard",
      //   meta: { title: "首页", icon: "dashboard", affix: true },
      //   component: () => import("@/views/dashboard/index")
      // }
    ],
  },
  {
    path: "",
    component: Layout,
    name: "Info",
    hidden: true,
    children: [
      {
        path: "/info",
        component: () => import("@/views/info/index"),
      },
    ],
  },
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
];

//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: "history",
  routes: constantRouterMap,
});

//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "school",
    meta: {
      title: "学校管理",
      permissions: [
        "admin",
        "school_academy",
        "school_professional",
        "school_grade",
        "school_class",
        "school_student",
      ],
      icon: "users",
    },
    children: [
      {
        path: "/school/campus",
        name: "campus",
        component: () => import("@/views/school/campus"),
        // "school_campus"
        meta: { title: "校区管理", permissions: ["admin"] },
      },
      {
        path: "/arrange/classtime",
        name: "classtime",
        hidden: true,
        component: () => import("@/views/arrange/classtime"),
        meta: {
          title: "上课时间设置",
          permissions: ["admin", "arrange_classtime"],
        },
      },
      {
        path: "/school/academy",
        name: "academy",
        component: () => import("@/views/school/academy"),
        meta: { title: "院系列表", permissions: ["admin", "school_academy"] },
      },
      {
        path: "/school/grade",
        name: "grade",
        component: () => import("@/views/school/grade"),
        meta: { title: "年级管理", permissions: ["admin", "school_grade"] },
      },
      {
        path: "/school/professional",
        name: "professional",
        component: () => import("@/views/school/professional"),
        meta: {
          title: "专业列表",
          permissions: ["admin", "school_professional"],
        },
      },

      {
        path: "/school/class",
        name: "class",
        component: () => import("@/views/school/class"),
        meta: {
          keepAlive: true,
          title: "班级管理",
          permissions: ["admin", "school_class"],
        },
      },
      {
        path: "/school/student_manager",
        name: "class",
        hidden: true,
        component: () => import("@/views/school/student_manager"),
        meta: { title: "学生管理", permissions: ["admin", "school_class"] },
      },
      {
        path: "/school/class_student",
        name: "class_student",
        hidden: true,
        component: () => import("@/views/school/class_student"),
        meta: { title: "新增班级学生", permissions: ["admin", "school_class"] },
      },
      {
        path: "/school/check_curriculum",
        name: "check_curriculum",
        hidden: true,
        component: () => import("@/views/school/check_curriculum"),
        meta: { title: "查看课表", permissions: ["admin", "school_class"] },
      },
      {
        path: "/school/student",
        name: "student",
        component: () => import("@/views/school/student"),
        meta: { title: "学生管理", permissions: ["admin", "school_student"] },
      },
      {
        path: "/school/upgrade",
        name: "upgrade",
        component: () => import("@/views/school/upgrade"),
        meta: {
          title: "班级自动升级",
          permissions: ["admin", "school_student"],
        },
      },
    ],
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "course",
    meta: {
      title: "课程管理",
      permissions: ["admin", "course_ordinary", "course_gaming"],
      icon: "users",
    },
    children: [
      {
        path: "/course/ordinary",
        name: "ordinary",
        component: () => import("@/views/course/ordinary"),
        meta: {
          title: "普通课程列表",
          permissions: ["admin", "course_ordinary"],
        },
      },
      {
        path: "/course/gaming",
        name: "gaming",
        component: () => import("@/views/course/gaming"),
        meta: {
          title: "电竞课程列表",
          permissions: ["admin", "course_gaming"],
        },
      },
    ],
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "teacher",
    meta: {
      title: "教师管理",
      permissions: ["admin", "teacher_teachers", "teacher_eSportsTeachers"],
      icon: "users",
    },
    children: [
      {
        path: "/teacher/teachers",
        name: "teachers",
        component: () => import("@/views/teacher/teachers"),
        meta: { title: "教师列表", permissions: ["admin", "teacher_teachers"] },
      },
      {
        path: "/teacher/schedule",
        name: "schedule",
        hidden: true,
        component: () => import("@/views/teacher/schedule"),
        meta: { title: "查看课表", permissions: ["admin", "teacher_teachers"] },
      },
      {
        path: "/teacher/eSportsTeachers",
        name: "eSportsTeachers",
        component: () => import("@/views/teacher/eSportsTeachers"),
        meta: {
          title: "电竞教师列表",
          permissions: ["admin", "teacher_eSportsTeachers"],
        },
      },
      {
        path: "/teacher/record",
        name: "record",
        hidden: true,
        component: () => import("@/views/teacher/record"),
        meta: {
          title: "上课记录",
          permissions: ["admin", "teacher_distribute"],
        },
      },
      // {
      //   path: "/teacher/schedule",
      //   name: "schedule",
      //   hidden: true,
      //   component: () => import("@/views/teacher/schedule"),
      //   meta: { title: "查看课表", permissions: ["admin"] }
      // },
    ],
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "arrange",
    meta: {
      title: "排课管理",
      permissions: ["admin", "arrange_classtime", "arrange_curriculum"],
      icon: "users",
    },
    children: [
      {
        path: "/arrange/curriculum",
        name: "curriculum",
        component: () => import("@/views/arrange/curriculum"),
        meta: {
          keepAlive: true,
          title: "课程表设置",
          permissions: ["admin", "arrange_classtime"],
        },
      },
      {
        path: "/arrange/schedule",
        name: "schedule",
        component: () => import("@/views/arrange/schedule"),
        meta: {
          title: "排课记录",
          permissions: ["admin", "arrange_classtime"],
        },
      },
      {
        path: "/arrange/student",
        name: "schedule_student",
        component: () => import("@/views/arrange/student"),
        hidden:true,
        meta: {
          title: "查看学生",
          permissions: ["admin", "arrange_classtime"],
        },
      },
      {
        path: "/arrange/schedule_del",
        name: "schedule_del",
        component: () => import("@/views/arrange/schedule_del"),
        meta: {
          title: "批量删除课程",
          permissions: ["admin", "arrange_classtime"],
        },
      },
      {
        path: "/arrange/batch",
        name: "batch",
        hidden: true,
        component: () => import("@/views/arrange/batch"),
        meta: {
          title: "批量设置排课",
          permissions: ["admin", "arrange_curriculum"],
        },
      },
    ],
  },

  // {
  //   path: "",
  //   component: Layout,
  //   alwaysShow: true,
  //   name: "second",
  //   meta: { title: "第二堂课", permissions: ["admin"], icon: "users" },
  //   children: [
  //     {
  //       path: "/second/list",
  //       name: "list",
  //       component: () => import("@/views/second/list"),
  //       meta: { title: "排课列表", permissions: ["admin"] }
  //     },
  //     {
  //       path: "/second/signup",
  //       name: "list",
  //       hidden: true,
  //       component: () => import("@/views/second/signup"),
  //       meta: { title: "查看报名", permissions: ["admin"] }
  //     },
  //   ]
  // },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "education",
    meta: {
      title: "教务管理(电竞教师)",
      permissions: ["admin", "education_teachingtask"],
      icon: "users",
    },
    children: [
      {
        path: "/education/teachingtask",
        name: "teachingtask",
        component: () => import("@/views/education/teachingtask"),
        meta: {
          title: "教学任务管理",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/studentroster",
        name: "studentroster",
        hidden: true,
        component: () => import("@/views/education/studentroster"),
        meta: {
          title: "学生花名册",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/teacherprofile",
        name: "teacherprofile",
        hidden: true,
        component: () => import("@/views/education/teacherprofile"),
        meta: {
          title: "教师资料",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/classschedule",
        name: "classschedule",
        hidden: true,
        component: () => import("@/views/education/classschedule"),
        meta: {
          title: "班级课程表",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/studentfeedback",
        name: "studentfeedback",
        hidden: true,
        component: () => import("@/views/education/studentfeedback"),
        meta: {
          title: "学生反馈",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/instructionaldesign",
        name: "instructionaldesign",
        hidden: true,
        component: () => import("@/views/education/instructionaldesign"),
        meta: {
          title: "教学设计",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/coursewareinfo",
        name: "coursewareinfo",
        hidden: true,
        component: () => import("@/views/education/coursewareinfo"),
        meta: {
          title: "课件资料",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/studentexam",
        name: "studentexam",
        hidden: true,
        component: () => import("@/views/education/studentexam"),
        meta: {
          title: "学生考试",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/studentscore",
        name: "studentscore",
        hidden: true,
        component: () => import("@/views/education/studentscore"),
        meta: {
          title: "学生成绩",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/teachersummary",
        name: "teachersummary",
        hidden: true,
        component: () => import("@/views/education/teachersummary"),
        meta: {
          title: "教师总结",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/assessment",
        name: "assessment",
        hidden: true,
        component: () => import("@/views/education/assessment"),
        meta: {
          title: "教学评定",
          permissions: ["admin", "education_teachingtask"],
        },
      },
      {
        path: "/education/checkreviews",
        name: "checkreviews",
        hidden: true,
        component: () => import("@/views/education/checkreviews"),
        meta: {
          title: "查课评价",
          permissions: ["admin", "education_teachingtask"],
        },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "educationin",
    meta: {
      title: "教务管理(学校教师)",
      permissions: [
        "admin",
        "educationin_schoolinfo",
        "educationin_teachingtask",
      ],
      icon: "users",
    },
    children: [
      {
        path: "/educationin/schoolinfo",
        name: "schoolinfo",
        component: () => import("@/views/educationin/schoolinfo"),
        meta: {
          title: "学校资料",
          permissions: ["admin", "educationin_schoolinfo"],
        },
      },
      {
        path: "/educationin/teachingtask",
        name: "teachingtask",
        component: () => import("@/views/educationin/teachingtask"),
        meta: {
          keepAlive: true,
          title: "教学任务管理",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/studentroster",
        name: "studentroster",
        hidden: true,
        component: () => import("@/views/educationin/studentroster"),
        meta: {
          title: "学生花名册",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/teacherprofile",
        name: "teacherprofile",
        hidden: true,
        component: () => import("@/views/educationin/teacherprofile"),
        meta: {
          title: "教师资料",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/classschedule",
        name: "classschedule",
        hidden: true,
        component: () => import("@/views/educationin/classschedule"),
        meta: {
          title: "班级课程表",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/studentfeedback",
        name: "studentfeedback",
        hidden: true,
        component: () => import("@/views/educationin/studentfeedback"),
        meta: {
          title: "学生反馈",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/instructionaldesign",
        name: "instructionaldesign",
        hidden: true,
        component: () => import("@/views/educationin/instructionaldesign"),
        meta: {
          title: "教学设计",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/coursewareinfo",
        name: "coursewareinfo",
        hidden: true,
        component: () => import("@/views/educationin/coursewareinfo"),
        meta: {
          title: "课件资料",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/studentexam",
        name: "studentexam",
        hidden: true,
        component: () => import("@/views/educationin/studentexam"),
        meta: {
          title: "学生考试",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/studentscore",
        name: "studentscore",
        hidden: true,
        component: () => import("@/views/educationin/studentscore"),
        meta: {
          title: "学生成绩",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/teachersummary",
        name: "teachersummary",
        hidden: true,
        component: () => import("@/views/educationin/teachersummary"),
        meta: {
          title: "教师总结",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/assessment",
        name: "assessment",
        hidden: true,
        component: () => import("@/views/educationin/assessment"),
        meta: {
          title: "教学评定",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
      {
        path: "/educationin/checkreviews",
        name: "checkreviews",
        hidden: true,
        component: () => import("@/views/educationin/checkreviews"),
        meta: {
          title: "查课评价",
          permissions: ["admin", "educationin_teachingtask"],
        },
      },
    ],
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "E-sportsManagement",
    meta: {
      title: "技能课程管理",
      permissions: ["admin", "e_skillCourse", "e_curriculumList"],
      icon: "example",
    },
    children: [
      {
        path: "/E-sportsManagement/skillCourse",
        name: "skillCourse",
        component: () => import("@/views/E-sportsManagement/skillCourse"),
        meta: { title: "技能课程列表", permissions: ["admin", "e_skillCourse"] }
      },
      {
        path: "/E-sportsManagement/skillCourse_edit",
        name: "skillCourse_edit",
        hidden: true,
        component: () => import("@/views/E-sportsManagement/skillCourse_edit"),
        meta: {
          title: "技能课程_添加/修改",
          permissions: ["admin", "e_skillCourse"],
        },
      },
      {
        path: "/E-sportsManagement/curriculumList",
        name: "curriculumList",
        component: () => import("@/views/E-sportsManagement/curriculumList"),
        meta: {
          keepAlive: true,
          title: "课程排课列表",
          permissions: ["admin", "e_curriculumList"],
        },
      },
      {
        path: "/E-sportsManagement/curriculumList_edit",
        name: "curriculumList_edit",
        hidden: true,
        component: () =>
          import("@/views/E-sportsManagement/curriculumList_edit"),
        meta: {
          title: "课程排课_添加/修改",
          permissions: ["admin", "e_curriculumList"],
        },
      },
      {
        path: "/E-sportsManagement/lookApply",
        name: "lookApply",
        hidden: true,
        component: () => import("@/views/E-sportsManagement/lookApply"),
        meta: { title: "查看报名", permissions: ["admin", "e_curriculumList"] },
      },
    ],
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "match",
    meta: {
      title: "赛事管理",
      permissions: ["admin", "match_schedule"],
      icon: "users",
    },
    children: [
      {
        path: "/match/schedule",
        name: "teachers",
        component: () => import("@/views/match/schedule"),
        meta: {
          keepAlive: true,
          title: "赛事列表",
          permissions: ["admin", "match_schedule"],
        },
      },
      {
        path: "/match/Banner",
        name: "Banner",
        component: () => import("@/views/match/Banner"),
        meta: { title: "赛事轮播图", permissions: ["admin", "match_schedule"] },
      },
      {
        path: "/match/bannerDetails",
        name: "bannerDetails",
        hidden: true,
        component: () => import("@/views/match/bannerDetails"),
        meta: { title: "轮播图详情", permissions: ["admin", "match_schedule"] },
      },
      {
        path: "/match/competitionarrange",
        name: "competitionarrange",
        hidden: true,
        component: () => import("@/views/match/competitionarrange"),
        meta: { title: "赛程安排", permissions: ["admin", "match_schedule"] },
      },
      {
        path: "/match/checkdetails",
        name: "checkdetails",
        hidden: true,
        component: () => import("@/views/match/checkdetails"),
        meta: { title: "赛程安排", permissions: ["admin", "match_schedule"] },
      },
      {
        path: "/match/viewranking",
        name: "viewranking",
        hidden: true,
        component: () => import("@/views/match/viewranking"),
        meta: { title: "查看排行", permissions: ["admin", "match_schedule"] },
      },
      {
        path: "/match/applygame",
        name: "applygame",
        hidden: true,
        component: () => import("@/views/match/applygame"),
        meta: { title: "查看报名", permissions: ["admin"] },
      },
      {
        path: "/match/cancel",
        name: "cancel",
        hidden: true,
        component: () => import("@/views/match/cancel"),
        meta: { title: "取消报名管理", permissions: ["admin"] },
      },
    ],
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "role",
    meta: {
      title: "权限管理",
      permissions: ["admin"],
      icon: "content",
    },
    children: [
      {
        path: "/roles",
        name: "role",
        component: () => import("@/views/role/index"),
        meta: { title: "角色列表", permissions: ["role"] },
      },
      {
        path: "/admin/list",
        name: "admin",
        component: () => import("@/views/admin/index"),
        meta: { title: "管理员列表", permissions: ["admin"] },
      },
    ],
  },

  { path: "*", redirect: "/error/404", hidden: true },
];
